import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {BaseService} from './base-service';
import {ApiService} from './api.service';
import {UserService} from './user.service';
import {ewsApiRoutesConstants} from '../shared/constants/ews-api-routes.constants';
import {Observable, of} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {ApplicationService} from './application.service';
import {constants} from '../shared/constants/constants';
import {
    BenchmarkClusterResponse,
    BenchmarkDeviceCategoriesResponse,
    BenchmarkFilterMetaPayload,
    BenchmarkOptOutResponse,
    BenchmarkRankingResponse
} from '../shared/interfaces/benchmark.interfaces';
import {HouseholdComparisonTimeframe} from '../shared/enums/household-comparison-timeframe.enum';


@Injectable({
    providedIn: 'root'
})
export class BenchmarkService extends BaseService {

    private readonly DEMO_BASE_PATH = 'assets/data/demo/benchmark';
    public hasOwnClusterData = false;


    constructor(
        protected http: HttpClient,
        protected auth: ApiService,
        protected user: UserService,
        private application: ApplicationService
    ) {
        super(http, auth, user);
    }


    /**
     * Get benchmark cluster.
     * @param payload - benchmark filter payload
     */
    getCluster({payload}: BenchmarkFilterMetaPayload): Observable<BenchmarkClusterResponse> {
        if (this.application.isDemoMode()) {
            let demoFile = '';
            switch (payload.period) {
                case HouseholdComparisonTimeframe.LAST_MONTH:
                    demoFile = constants.demo.files.benchmark.cluster.lastMonth;
                    break;
                case HouseholdComparisonTimeframe.LAST_YEAR:
                    demoFile = constants.demo.files.benchmark.cluster.lastYear;
                    break;
                case HouseholdComparisonTimeframe.CURRENT_YEAR:
                    demoFile = constants.demo.files.benchmark.cluster.currentYear;
                    break;
            }
            return this.http.get<BenchmarkClusterResponse>
            (`${this.DEMO_BASE_PATH}/${demoFile}.json`);
        }
        const url = `${this.EWS_BASE_URL}/${ewsApiRoutesConstants.benchmarkCluster}`;
        return this.http.post<BenchmarkClusterResponse>(url, payload).pipe(
            map((response) => {
                const deepCopy = JSON.parse(JSON.stringify(response));

                const indexOfMyAvg = deepCopy.rows.findIndex((bucket) => 'me' in bucket);

                this.hasOwnClusterData = indexOfMyAvg !== -1;

                if (indexOfMyAvg === -1) {
                    return deepCopy;
                }

                const myBucket = JSON.parse(JSON.stringify(deepCopy.rows[indexOfMyAvg]));

                delete myBucket?.me;
                deepCopy.rows[indexOfMyAvg].avg = deepCopy.rows[indexOfMyAvg]?.me;

                deepCopy.rows.splice(indexOfMyAvg, 0, myBucket);

                deepCopy.rows.sort((a, b) => a.avg - b.avg);

                deepCopy.rows.forEach((bucket, index) => {
                    bucket.buckets = index + 1;
                });


                const newIndex = deepCopy.rows.findIndex((bucket) => 'me' in bucket);

                if (deepCopy.rows[newIndex].avg === deepCopy.rows?.[newIndex - 1]?.avg) {
                    const index = deepCopy.rows[newIndex].buckets;
                    deepCopy.rows[newIndex].buckets = deepCopy.rows?.[newIndex - 1].buckets;
                    deepCopy.rows[newIndex - 1].buckets = index;
                }

                return deepCopy;
            }),
            catchError(this.handleError)
        );
    }


    /**
     * Get benchmark ranking.
     * @param payload - benchmark filter payload
     * @param reduced - request the reduced dataset
     */
    getRanking(
        {payload}: BenchmarkFilterMetaPayload,
        reduced = false
    ): Observable<BenchmarkRankingResponse> {
        if (this.application.isDemoMode()) {
            let demoFile = '';
            switch (payload.period) {
                case HouseholdComparisonTimeframe.LAST_MONTH:
                    demoFile = constants.demo.files.benchmark.ranking.lastMonth;
                    break;
                case HouseholdComparisonTimeframe.LAST_YEAR:
                    demoFile = constants.demo.files.benchmark.ranking.lastYear;
                    break;
                case HouseholdComparisonTimeframe.CURRENT_YEAR:
                    demoFile = constants.demo.files.benchmark.ranking.currentYear;
                    break;
            }
            return this.http.get<BenchmarkRankingResponse>
            (`${this.DEMO_BASE_PATH}/${demoFile}.json`);
        }
        const url = `${this.EWS_BASE_URL}/${ewsApiRoutesConstants.benchmarkRanking}`;
        return this.http.post<BenchmarkRankingResponse>(
            url,
            payload,
            {params: {reduced}}
        ).pipe(catchError(this.handleError));
    }


    /**
     * Get benchmark device categories.
     * @param payload - benchmark filter payload
     */
    getDeviceCategories({payload}: BenchmarkFilterMetaPayload)
        : Observable<BenchmarkDeviceCategoriesResponse> {
        if (this.application.isDemoMode()) {
            let demoFile = '';
            switch (payload.period) {
                case HouseholdComparisonTimeframe.LAST_MONTH:
                    demoFile = constants.demo.files.benchmark.deviceCategories.lastMonth;
                    break;
                case HouseholdComparisonTimeframe.LAST_YEAR:
                    demoFile = constants.demo.files.benchmark.deviceCategories.lastYear;
                    break;
                case HouseholdComparisonTimeframe.CURRENT_YEAR:
                    demoFile = constants.demo.files.benchmark.deviceCategories.currentYear;
                    break;
            }
            return this.http.get<BenchmarkDeviceCategoriesResponse>
            (`${this.DEMO_BASE_PATH}/${demoFile}.json`);
        }
        const url = `${this.EWS_BASE_URL}/${ewsApiRoutesConstants.benchmarkDeviceCategories}`;
        return this.http.post<BenchmarkDeviceCategoriesResponse>(url, payload).pipe(
            catchError(this.handleError)
        );
    }


    /**
     * Get benchmark opt-out settings.
     *   - gets the current opt-out setting
     *     - if the setting is 404 (not found), the user is opted in
     *     - if the setting is any positive response, the user is opted out
     *   - returns false in demo mode - user is NOT opted out
     */
    getOptOutSetting(): Observable<boolean> {
        if (this.application.isDemoMode()) {
            return of(false);
        }
        return this.http.get<BenchmarkOptOutResponse>(`${this.EWS_BASE_URL}/${ewsApiRoutesConstants.benchmarkOptOut}`).pipe(
            map((response) => {
                if (response) {
                    return true;
                }
                return false;
            }),
            catchError((error: HttpErrorResponse) => {
                if (error.status === 404) {
                    return of(false);
                }
            })
        );
    }


    /**
     * Delete benchmark opt-out settings.
     *  - means, that the opt-out on the server is deleted, hence the user is opted in again.
     */
    deleteOptOutSetting(): Observable<any> {
        return this.http.delete(`${this.EWS_BASE_URL}/${ewsApiRoutesConstants.benchmarkOptOut}`);
    }


    /**
     * Set benchmark opt-out settings.
     *  - means, that the opt-out on the server is set, hence the user is opted out.
     */
    setOptOutSetting(): Observable<any> {
        return this.http.put(`${this.EWS_BASE_URL}/${ewsApiRoutesConstants.benchmarkOptOut}`, {});
    }


}

