import {Injectable, OnDestroy} from '@angular/core';
import {catchError, mergeMap, tap} from 'rxjs/operators';
import * as moment from 'moment';
import {ProfileService} from '../profile.service';
import {BehaviorSubject, Observable, of, zip} from 'rxjs';
import {
    HouseholdComparisonAppliancesData,
    HouseholdComparisonData,
    HouseholdComparisonDiagramDataEntry,
    HouseholdComparisonProps,
    HouseholdComparisonRankingData,
    initialHouseholdComparisonAppliancesData,
    initialHouseholdComparisonData,
    initialHouseholdComparisonRankingData
} from '../../shared/interfaces/household-comparison.interfaces';
import {BenchmarkService} from '../benchmark.service';
import {
    BenchmarkClusterResponse,
    BenchmarkDeviceCategoriesResponse,
    BenchmarkFilterMetaPayload,
    BenchmarkFilterPayloadProps,
    BenchmarkRankingResponse,
    emptyBenchmarkClusterPayloadProps
} from '../../shared/interfaces/benchmark.interfaces';
import {HouseholdProfile} from '../../shared/interfaces/profile-attributes.interfaces';
import {ViewState} from '../../shared/enums/view-state.enum';
import {HouseholdComparisonTimeframe} from '../../shared/enums/household-comparison-timeframe.enum';
import {UserService} from '../user.service';
import {
    HouseholdComparisonRankRowConfig
} from '../../components/household-comparison/household-comparison-rank-row/household-comparison-rank-row.component';
import {TranslateService} from '@ngx-translate/core';


@Injectable({
    providedIn: 'root'
})
export class HouseholdComparisonDataProviderService implements OnDestroy {

    private readonly timeframeRequestFormat = 'YYYY-MM-DD';
    private readonly deviceCategoryTotalKey = 'electricity_total';

    private currentTimeFrameDates: TimeframeDates;
    private deviceCategoriesModeActive = false;


    currentTimeframe = HouseholdComparisonTimeframe.LAST_MONTH;
    currentProps: HouseholdComparisonProps = {
        occupants: false,
        spaceHeating: false,
        waterHeating: false,
        propertyType: false,
        evCharger: false,
        swimmingPool: false,
        sauna: false,
    };

    combinedClusterRankingData$ = new BehaviorSubject<HouseholdComparisonData>(
        initialHouseholdComparisonData
    );

    deviceCategoriesData$ = new BehaviorSubject<HouseholdComparisonAppliancesData>(
        initialHouseholdComparisonAppliancesData
    );

    rankingData$ = new BehaviorSubject<HouseholdComparisonRankingData>(
        initialHouseholdComparisonRankingData
    );

    filterChanged$ = new BehaviorSubject<BenchmarkFilterPayloadProps>(
        emptyBenchmarkClusterPayloadProps
    );

    optedOut$ = new BehaviorSubject<boolean>(false);


    constructor(
        private benchmarkService: BenchmarkService,
        private profileService: ProfileService,
        private userService: UserService,
        private translate: TranslateService
    ) {
        this.getCurrentProps();
        this.getCurrentStoredTimeframe();
    }


    ngOnDestroy() {
        this.generateStartAndEndDatesForTimeframe();
    }


    /**
     * Returns combined data necessary to display the household comparison tile.
     *
     * The data is combined from the following sources:
     * - Benchmark Cluster data
     * - Benchmark Ranking data
     * - Electricity Consumption data
     * - Profile attributes (to generate the cluster call payload)
     */
    getCombinedClusterRankingData(): void {
        this.getCurrentStoredTimeframe();
        this.combinedClusterRankingData$.next({
            ...initialHouseholdComparisonData,
            viewState: ViewState.LOADING
        });
        this.generateStartAndEndDatesForTimeframe();
        this.requestOptOutStatus$().pipe(
            mergeMap(optOutStatus => {
                if (!optOutStatus) {
                    return this.requestClusterAndRankingData$().pipe(
                        mergeMap(([clusterData, rankingData]) =>
                            of({clusterData, rankingData})
                        ),
                    );
                }
                return of(null);
            }),
            mergeMap(combinedData => {
                if (combinedData === null) {
                    return of({
                        ...initialHouseholdComparisonData,
                        viewState: ViewState.OPT_OUT,
                    });
                }
                return this.alignCombinedClusterRankingData$(
                    combinedData.clusterData,
                    combinedData.rankingData
                );
            }),
            // tap(data => console.log('tile-data', data)),
            catchError(error => {
                console.log('Error combined cluster ranking data:', error);
                return of({
                    ...initialHouseholdComparisonData,
                    viewState: ViewState.ERROR,
                });
            }),
        ).subscribe(data => {
            this.combinedClusterRankingData$.next(data);
        });
    }


    /**
     * Returns combined data necessary to display the appliances detail view section.
     */
    getDeviceCategoriesData(): void {
        this.getCurrentStoredTimeframe();
        this.deviceCategoriesData$.next({
            ...initialHouseholdComparisonAppliancesData,
            viewState: ViewState.LOADING
        });
        this.generateStartAndEndDatesForTimeframe();
        this.requestDeviceCategoriesData$().pipe(
            mergeMap(deviceCategoriesData =>
                this.alignDeviceCategoriesData$(deviceCategoriesData)
            ),
            catchError(error =>
                of({
                    ...initialHouseholdComparisonAppliancesData,
                    viewState: ViewState.ERROR,
                })
            )
        ).subscribe(data => {
            this.deviceCategoriesData$.next(data);
        });
    }


    getRankingData(): void {
        this.getCurrentStoredTimeframe();
        this.rankingData$.next({
            ...initialHouseholdComparisonRankingData,
            viewState: ViewState.LOADING,
        });
        this.generateStartAndEndDatesForTimeframe();
        this.requestRankingData$(false).pipe(
            mergeMap(rankingData =>
                this.alignRankingData$(rankingData)
            ),
            catchError(error => {
                console.log('Error ranking data:', error);
                return of({
                    ...initialHouseholdComparisonRankingData,
                    viewState: ViewState.ERROR,
                } as HouseholdComparisonRankingData);
            })
        ).subscribe(data => {
            this.rankingData$.next(data);
        });
    }


    /**
     * Sets whether the device categories mode is active or not.
     * Meaning if the user is currently viewing the device categories detail view tab.
     * @param isActive
     */
    setDeviceCategoriesModeIsActive(isActive: boolean): void {
        this.deviceCategoriesModeActive = isActive;
    }


    /**
     * Sets new props from detail filter form.
     * @param props
     */
    setProps(props: HouseholdComparisonProps): void {
        this.currentProps = props;
        this.userService.setCurrentHouseholdComparisonFilter(props);
        this.getCombinedClusterRankingData();
        if (this.deviceCategoriesModeActive) {
            this.getDeviceCategoriesData();
        }
    }


    /**
     * Sets new timeframe from detail view.
     * @param timeframe
     */
    setTimeframe(timeframe: HouseholdComparisonTimeframe): void {
        if (timeframe === this.currentTimeframe) {
            return;
        }
        this.currentTimeframe = timeframe;
        this.userService.setCurrentHouseholdComparisonTimeframe(this.currentTimeframe);
        this.getCombinedClusterRankingData();
        if (this.deviceCategoriesModeActive) {
            this.getDeviceCategoriesData();
        }
    }


    /**
     * Returns the current timeframe.
     */
    getCurrentStoredTimeframe(): HouseholdComparisonTimeframe {
        const timeframe = this.userService.getCurrentHouseholdComparisonTimeframe();
        if (!timeframe) {
            this.currentTimeframe = HouseholdComparisonTimeframe.LAST_MONTH;
        } else {
            this.currentTimeframe = timeframe;
        }
        return this.currentTimeframe;
    }


    /**
     * Sets new timeframe from detail filter form.
     */
    getCurrentProps(): HouseholdComparisonProps {
        // todo load props from local storage
        const loadedProps = this.userService.getCurrentHouseholdComparisonFilter();
        if (loadedProps) {
            this.currentProps = loadedProps;
        }
        return this.currentProps;
    }


    /**
     * Returns a formatted string for the current timeframe.
     */
    getCurrentTimeframeFormatted(): string {
        let from: string;
        let to: string;
        switch (this.currentTimeframe) {
            case HouseholdComparisonTimeframe.CURRENT_YEAR:
                from = this.currentTimeFrameDates.from.format('DD.MM.');
                to = moment().subtract(1, 'months').endOf('month').format('DD.MM.YYYY');
                return `${from} – ${to}`;
            case HouseholdComparisonTimeframe.LAST_MONTH:
                from = this.currentTimeFrameDates.from.format('DD.MM');
                to = this.currentTimeFrameDates.to.format('DD.MM.YYYY');
                return `${this.currentTimeFrameDates.from.format('MMMM YYYY')}`;
            case HouseholdComparisonTimeframe.LAST_YEAR:
                return this.currentTimeFrameDates.from.format('YYYY');
        }
    }


    /**
     * Returns whether the user has opted out of benchmarking.
     * @private
     */
    private requestOptOutStatus$(): Observable<boolean> {
        return this.benchmarkService.getOptOutSetting().pipe(
            tap(optOut => this.optedOut$.next(optOut))
        );
    }


    /**
     * Requests the cluster and ranking data from the backend.
     * Profile Attributes are requested first to generate the cluster-call payload.
     * @private
     */
    private requestClusterAndRankingData$():
        Observable<[BenchmarkClusterResponse, BenchmarkRankingResponse]> {
        return this.profileService.getAttributesWithCache().pipe(
            mergeMap(profileAttributes => {
                const payload = this.generateBenchmarkFilterPayload(profileAttributes);
                return zip(
                    this.benchmarkService.getCluster(payload),
                    this.benchmarkService.getRanking(payload, true),
                );
            })
        );
    }


    /**
     * Requests the cluster and appliances data from the backend.
     * @private
     */
    private requestDeviceCategoriesData$(): Observable<BenchmarkDeviceCategoriesResponse> {
        return this.profileService.getAttributesWithCache().pipe(
            mergeMap(profileAttributes => {
                    const payload = this.generateBenchmarkFilterPayload(profileAttributes);
                    return this.benchmarkService.getDeviceCategories(payload);
                }
            )
        );
    }


    /**
     * Requests ranking data from the backend.
     * @private
     */
    private requestRankingData$(reduced = true): Observable<BenchmarkRankingResponse> {
        return this.profileService.getAttributesWithCache().pipe(
            mergeMap(profileAttributes => {
                    const payload = this.generateBenchmarkFilterPayload(profileAttributes);
                    return this.benchmarkService.getRanking(payload, reduced);
                }
            )
        );
    }


    /**
     * Generates start and end dates for the current timeframe;
     * @private
     */
    private generateStartAndEndDatesForTimeframe() {
        switch (this.currentTimeframe) {
            case HouseholdComparisonTimeframe.CURRENT_YEAR:
                this.currentTimeFrameDates = {
                    from: moment().startOf('year'),
                    to: moment().locale('de-DE')
                };
                return;
            case HouseholdComparisonTimeframe.LAST_YEAR:
                this.currentTimeFrameDates = {
                    from: moment().subtract(1, 'year')
                        .startOf('year'),
                    to: moment().subtract(1, 'year')
                        .endOf('year')
                        .add(1, 'day')
                };
                return;
            case HouseholdComparisonTimeframe.LAST_MONTH:
                this.currentTimeFrameDates = {
                    from: moment().locale('de-DE')
                        .subtract(1, 'month')
                        .startOf('month'),
                    to: moment().locale('de-DE')
                        .subtract(1, 'month')
                        .endOf('month')
                        .add(1, 'day')
                };
                return;
        }
    }


    /**
     * Generates the payload for the benchmark API calls.
     * @param profileAttributes
     * @private
     */
    private generateBenchmarkFilterPayload(
        profileAttributes: HouseholdProfile
    ): BenchmarkFilterMetaPayload {
        const attributes = profileAttributes.Attributes;
        const appliances = profileAttributes.Appliances;
        const props: BenchmarkFilterPayloadProps = {
            occupants: this.currentProps.occupants ? attributes.Occupants : undefined,
            space_heating: this.currentProps.spaceHeating ? attributes.SpaceHeating : undefined,
            water_heating: this.currentProps.waterHeating ? attributes.WaterHeating : undefined,
            property_type: this.currentProps.propertyType ? attributes.PropertyType : undefined,
            ev_charger: this.currentProps.evCharger ? appliances['A.21'] : undefined,
            swimming_pool: this.currentProps.swimmingPool
                ? appliances['A.22'] : undefined,
            sauna: this.currentProps.sauna ? appliances['A.23'] : undefined,
        };

        this.filterChanged$.next(props);

        return {
            payload: {
                period: this.currentTimeframe,
                props
            }
        };
    }


    /**
     * Aligns the cluster and ranking data to be displayed by the tile or in the total detail-view
     * diagram.
     * @param clusterData
     * @param rankingData
     * @private
     */
    private alignCombinedClusterRankingData$(
        clusterData: BenchmarkClusterResponse,
        rankingData: BenchmarkRankingResponse
    ): Observable<HouseholdComparisonData> {
        return new Observable<HouseholdComparisonData>(subscriber => {
            try {
                let viewState = clusterData.rows.length > 0 ? ViewState.SUCCESS : ViewState.EMPTY;
                if (rankingData.rank_me === 0) {
                    viewState = ViewState.EMPTY;
                }

                const mapped = clusterData.rows.map(row => {
                    return {
                        y: row.avg,
                        x: row.buckets - 1,
                        highlighted: 'me' in row,
                    };
                }) as Array<HouseholdComparisonDiagramDataEntry>;
                const averageBucketIdx = clusterData.rows.findIndex(row => row.global_avg);

                let consumptionValue = 0;
                const consumption = clusterData.rows.find(entry => 'me' in entry);
                if (consumption) {
                    consumptionValue = consumption?.me;
                }
                if (mapped.length === 0) {
                    viewState = ViewState.EMPTY;
                }

                if (!this.benchmarkService.hasOwnClusterData){
                    viewState = ViewState.NO_DATA;
                }

                subscriber.next({
                    averageConsumption: clusterData.global_avg,
                    consumption: consumptionValue,
                    diagramData: {
                        timeframe: this.currentTimeframe,
                        data: mapped,
                        averageBucketIdx,
                        averageConsumption: clusterData.global_avg,
                        highlightedBucketCalloutValue: consumptionValue,
                    },
                    rank: rankingData.rank_me,
                    comparableHouseholds: clusterData.count,
                    viewState,
                });
            } catch (error) {
                console.log('Error aligning cluster and ranking data: ', error);
                subscriber.error(error);
            }
            subscriber.complete();
        });
    }


    /**
     * Aligns benchmark device-categories data to be consumed by the view model.
     * @param rawData
     * @private
     */
    private alignDeviceCategoriesData$(
        rawData: BenchmarkDeviceCategoriesResponse
    ): Observable<HouseholdComparisonAppliancesData> {
        return new Observable<HouseholdComparisonAppliancesData>(subscriber => {
            try {
                const totalIdx = rawData.rows.findIndex(
                    entry => entry.cat === this.deviceCategoryTotalKey
                );
                // filter out the total row
                const filtered = rawData.rows.filter(
                    entry => entry.cat !== this.deviceCategoryTotalKey
                );
                // sort by me_m
                let myConsumptionIsMaxRatio = false;
                for (const element of filtered) {
                    if (element.me_m === 1) {
                        myConsumptionIsMaxRatio = true;
                        break;
                    }
                }
                const sorted = filtered.sort(
                    (a, b) => {
                        if (myConsumptionIsMaxRatio) {
                            return b.me_m - a.me_m;
                        }
                        return b.other_m - a.other_m;
                    }
                );
                subscriber.next({
                    totalConsumptionMe: rawData.rows[totalIdx]?.me,
                    totalConsumptionOthers: rawData.rows[totalIdx].other,
                    viewState: !this.benchmarkService.hasOwnClusterData ? ViewState.NO_DATA : ViewState.SUCCESS,
                    deviceCategories: sorted.map(deviceRow => ({
                        name: deviceRow.cat.replace('electricity_', ''),
                        consumptionMe: deviceRow?.me,
                        consumptionOthers: deviceRow.other,
                        consumptionRatioMe: deviceRow.me_m * 100,
                        consumptionRatioOthers: deviceRow.other_m * 100,
                    })),
                } as HouseholdComparisonAppliancesData);
            } catch (error) {
                console.log('Error aligning device-category data:', error);
                subscriber.error(error);
            }
            subscriber.complete();
        });
    }


    /**
     * Aligns benchmark ranking data to be consumed by the view.
     * @param rawData
     * @private
     */
    private alignRankingData$(
        rawData: BenchmarkRankingResponse
    ): Observable<HouseholdComparisonRankingData> {
        return new Observable(subscriber => {
            try {
                let viewState = ViewState.SUCCESS;
                if (rawData.rows.length < 5) {
                    viewState = ViewState.EMPTY;
                }

                const rowConfigs: Array<HouseholdComparisonRankRowConfig> = [];


                if (!this.benchmarkService.hasOwnClusterData){
                    viewState = ViewState.NO_DATA;
                    subscriber.next({
                        rowConfigs,
                        viewState
                    });
                }

                const userRank = rawData.rank_me;
                const isInFirstRanks = userRank < 3;
                const isInLastRanks = userRank > rawData.rows.last().rank - 2;

                // construct ranks
                let ranks = [];
                if (isInFirstRanks || isInLastRanks) {
                    ranks.push(...rawData.rows.slice(0, 2));
                    ranks.push(...rawData.rows.slice(rawData.rows.length - 2, rawData.rows.length));
                } else {
                    ranks = rawData.rows.slice(0, 2);
                    ranks.push(rawData.rows.find(entry => entry.rank === userRank));
                    ranks.push(...rawData.rows.slice(rawData.rows.length - 2, rawData.rows.length));
                }

                // determine message
                let message = 'string';
                const ratio = rawData.rows.last().rank / userRank;
                if (userRank === 1 || userRank === 2) {
                    message = this.translate.instant('screens.dashboard.householdComparison.detailTexts.ranking.messages.top');
                } else {
                    if (ratio <= 1 / 3) {
                        message = this.translate.instant('screens.dashboard.householdComparison.detailTexts.ranking.messages.upperThird');
                    } else if (ratio <= (1 / 2)) {
                        message =  this.translate.instant('screens.dashboard.householdComparison.detailTexts.ranking.messages.belowAverage');
                    } else {
                        message = this.translate.instant('screens.dashboard.householdComparison.detailTexts.ranking.messages.rest');
                    }
                }


                // generate row configs
                for (const rank of ranks) {
                    rowConfigs.push({
                        rankingItem: {
                            rank: rank.rank,
                            consumption: parseFloat(rank.electricity_total),
                            me: rank.rank === userRank
                        },
                        showDots: rank.rank === userRank,
                        motivationalMessage: message,
                    });
                }

                // determine which row shows dots
                rowConfigs.map((row, idx) => {
                    if (idx === 0) {
                        row.showDots = false;
                    } else if (idx === 1) {
                        row.showDots = true;
                    } else if (idx === rowConfigs.length - 2) {
                        row.showDots = false;
                    } else if (idx === rowConfigs.length - 1) {
                        row.showDots = true;
                    }
                });
                rowConfigs.last().showDots = true;

                subscriber.next({
                    rowConfigs,
                    viewState
                });
            } catch (error) {
                console.log('Error aligning ranking data: ', error);
                subscriber.error(error);
            }
            subscriber.complete();
        });
    }

}


interface TimeframeDates {
    from: moment.Moment;
    to: moment.Moment;
}
