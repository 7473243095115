<div class="overlay">
    <div class="detail-overlay">

        <div class="detail-view detail-view-override">

            <section class="detail-head" [style.background]="headerColor">
                <header class="detail-header">
                    <h1 class="detail-heading">
                        {{LABELS.title}}
                    </h1>
                    <button class="iona-icon-only-button info white"
                            angulartics2On="click" angularticsCategory="Detailansichten"
                            angularticsAction="detail_info_text" angularticsLabel="Haushaltsvergleich"
                            (click)="infoVisible = !infoVisible">
                    </button>
                    <button class="iona-icon-only-button close white" (click)="close()"></button>
                </header>
            </section>

            <section class="detail-info" *ngIf="infoVisible">
                <div class="icon">
                    <i class="icon-info"></i>
                </div>
                <div class="info-text">
                    <h2 class="font-bold">
                        {{TEXTS.tileInfo.title}}
                    </h2>
                    <p *ngFor="let p of TEXTS.tileInfo.paragraphs">
                        {{p}}
                    </p>
                </div>
                <div class="close-info">
                    <button class="iona-icon-only-button close" (click)="infoVisible = false"></button>
                </div>
            </section>

            <div class="grow-space">
                <ng-container *ngIf="dataProviderService.optedOut$ | async; else detailContent">
                    <div class="detail-content optout">
                        <p> {{TEXTS.optedOut}} </p>
                        <button class="iona-button orange" (click)="routeToSettings()">
                            {{LABELS.detailBtnAdjustSettings}}
                        </button>
                    </div>
                </ng-container>
                <ng-template #detailContent>
                    <div class="tab-bar household-comparison">
                        <div [class.active]="currentViewMode === ViewMode.TOTAL"
                             (click)="setMode(ViewMode.TOTAL)">
                            {{LABELS.tabTotal}}
                        </div>
                        <div [class.active]="currentViewMode === ViewMode.APPLIANCES"
                             (click)="setMode(ViewMode.APPLIANCES)">
                            {{LABELS.tabAppliances}}
                        </div>
                        <div class="grow-space"></div>
                        <div [class.active]="currentViewMode === ViewMode.RANKING"
                             (click)="setMode(ViewMode.RANKING)">
                            <i class="trophy-s grey"></i>
                        </div>
                    </div>

                    <div class="main-stats" [class.hidden]="sidebarHidden">
                        <div class="timeframe font-bold">
                            {{dataProviderService.getCurrentTimeframeFormatted()}}
                        </div>
                        <div class="options p-t-m">
                            <select class="iona-select medium inverted green"
                                    (change)="timeframeChanged($event.target.value)"
                                    [value]="dataProviderService.getCurrentStoredTimeframe()">
                                <option [value]="Timeframes.LAST_MONTH"
                                        [selected]="currentTimeframe === Timeframes.LAST_MONTH">
                                    {{ LABELS.timeframeSelectLastMonth }}
                                </option>
                                <option [value]="Timeframes.CURRENT_YEAR"
                                        [selected]="currentTimeframe === Timeframes.CURRENT_YEAR">
                                    {{ LABELS.timeframeSelectCurrentYear }}
                                </option>
                                <option [value]="Timeframes.LAST_YEAR"
                                        [selected]="currentTimeframe === Timeframes.LAST_YEAR">
                                    {{ LABELS.timeframeSelectLastYear }}
                                </option>
                            </select>

                            <div class="spacer"></div>

                            <div class="current-rank"
                                 [class.disabled] = "!showRankingInfo"
                                 (click)="showRankingInfo ? setMode(ViewMode.RANKING) : null">

                                <i class="trophy-s"></i>

                                <ng-container *ngIf="householdComparisonData$ | async as householdComparisonData">
                                    <ng-container *ngIf="householdComparisonData.rank === 0 || !showRankingInfo">
                                        –
                                    </ng-container>
                                    <ng-container *ngIf="householdComparisonData.rank > 0 && showRankingInfo">
                                        {{ householdComparisonData.rank | largeInt }}
                                    </ng-container>
                                </ng-container>
                            </div>
                        </div>
                    </div>

                    <div class="detail-content"
                         [class.no-header]="sidebarHidden">
                        <div class="detail-visualization"
                             [class.full-width]="sidebarHidden">
                            <ng-template [cdkPortalOutlet]="selectedPortal"
                                         (attached)="onComponentAttachedToPortal($event)">
                            </ng-template>
                        </div>
                        <div class="detail-sidebar" [style.display]="determineSidebarDisplay()">
                            <app-household-comparison-detail-sidebar>
                            </app-household-comparison-detail-sidebar>
                        </div>
                    </div>
                </ng-template>


            </div>
        </div>

    </div>
</div>
